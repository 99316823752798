import { merge } from "theme-ui"
import { DefaultTheme, ab } from "gatsby-theme-gba"

const groupColor = "#004787"

const theme = merge(DefaultTheme.theme, {
  colors: {
    primary: groupColor,
    modes: {
      abf: {
        text: "#5a5d5f",
        background: "#fff",
      },
      lkf: {
        text: "#5a5d5f",
        background: "#fff",
      },
    },
    navigation: {
      lkf: groupColor,
      abf: groupColor,
    },
  },

  navigation: {
    languageSwitcher: {
      display: "none",
    },
    nav: {
      zIndex: 101,
    },
    layer: {
      height: ab({ _: "calc(100% - 72px)", md: "auto" }),
    },
    unorderedList: {
      height: ab({ _: "72px", md: "calc(100% - 140px)" }),
    },
    mainItemDiv: {
      " > a": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: " 45px",
      },
      ".main-navigation-item-text": {
        maxWidth: ab({ _: "80px", md: "auto" }),
      },
    },
  },

  header: {
    languageSwitcher: {
      display: "none",
    },
  },
  searchModal: {
    ".refinements-controls, .option-text, .search-toggle-refinement": {
      display: "none",
    },
    ".result-list": {
      marginTop: "50px",
    },
  },
  guc: {
    mainNavigationItem: {
      lineHeight: "9px",
    },
  },
})

export default theme
