/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, Fragment } from "react"
import PropTypes from "prop-types"

import { ab } from "../theme"
import Button from "./button"

const SearchToggleRefinement = ({ buttonText, children }) => {
  const [toggleRefinement, setToggleRefinement] = useState(false)

  return (
    <Fragment>
      <div
        className="search-toggle-refinement"
        sx={{
          mt: 3,
          display: ab({ md: "none" }),
        }}
      >
        <Button
          onClick={() => setToggleRefinement(!toggleRefinement)}
          buttonText={buttonText}
          style={{
            border: 0,
            color: toggleRefinement ? "text" : "#fff",
            backgroundColor: toggleRefinement ? "#ccc" : "primary",
            backgroundPosition: "20px 50%",
            backgroundSize: "15px",
            backgroundRepeat: "no-repeat",
            backgroundImage: toggleRefinement
              ? "url(/search-filter-active.png)"
              : "url(/search-filter.png)",
            pl: "45px",
            ":hover": {
              backgroundColor: toggleRefinement ? "#ccc" : "primary",
              color: toggleRefinement ? "text" : "#fff",
            },
          }}
        />
      </div>
      <div
        sx={{
          transition: ab({ _: "all .4s ease-in-out", md: "none" }),
          maxHeight: ab({
            _: toggleRefinement ? "300px" : 0,
            md: "none",
          }),
          overflow: "hidden",
        }}
      >
        {children}
      </div>
    </Fragment>
  )
}

SearchToggleRefinement.propTypes = {
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default SearchToggleRefinement
